body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    flex: 1 1;
    flex-wrap: nowrap;
    background-size: cover;
    overflow: hidden;
    background: #000;
}

@keyframes gradient-wave {
    0% {
        background-position: 50% 0;
    }

    50% {
        background-position: 50% 100%;
    }

    100% {
        background-position: 50% 0;
    }
}

@keyframes gradient-wave-inverse {
    0% {
        background-position: 50% 100%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 50% 100%;
    }
}

@keyframes skew {
    0% {
        transform: skewX(0deg);
    }

    30% {
        transform: skewX(10deg);

    }

    60% {
        transform: skewX(-10deg);
    }

    100% {
        transform: skewX(0deg);
    }
}